<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-5">
        <h4>Details</h4>
        <p>
          Client: {{ estimate.client.name }}<br />
          Brand: {{ estimate.brand.name }}<br />
          Job Number: {{ estimate.job_number.job_number }}<br />
          Job Description: {{ estimate.job_number.description }}<br />
          Created by: {{ estimate.user.first_name }} {{ estimate.user.last_name }}
          <span v-if="estimate.po_number.length > 0">
            <br />PO Number: {{ estimate.po_number }}
          </span>
          <span v-if="estimate.po_file.id">
            <br />View PO:
            <!-- eslint-disable max-len -->
            <a
              :href="`${baseUrl}/original/${estimate.po_file.assembly}__${estimate.po_file.name}.${estimate.po_file.ext}`"
              target="_blank">{{ estimate.po_file.name }}</a>
            <!-- eslint-enable max-len -->
          </span>
        </p>
      </div>
      <div class="cell small-12 medium-5">
        <h4>Notes</h4>
        <div
          v-for="(note, index) in estimate.estimate_notes"
          :key="`en_${index}`"
          v-html="note.note">
        </div>
      </div>
      <div class="cell small-12 medium-2 text-right">
        <button @click="$emit('select', estimate.id)" class="button small">Select</button>
          &nbsp;
        <button @click="$emit('cancel')" class="button small red">Cancel</button>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-4">
        <div class="cell small-12">
          <h4>Estimate includes</h4>
          <p>{{ estimate.estimate_includes }}</p>
        </div>
      </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <h4 class="pb-2">Fee Estimate</h4>
        <div v-if="estimate.show_breakdown">
          <table v-for="(group, index) in estimate.internal" :key="`g_${index}`">
            <thead>
              <tr>
                <th style="width:60%">{{ group.name }}</th>
                <th style="width:20%">Hours</th>
                <th class="text-right" style="width:20%">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                <td>
                  <span v-if="line.external === false">
                    {{ line.personnel }}
                  </span>
                  <span v-else>
                    {{ line.description }}
                  </span>
                </td>
                <td>
                  <span v-if="line.external === false">
                    {{ line.quantity }}
                  </span>
                </td>
                <td class="text-right">{{ (line.cost_each * line.quantity) | currency }}</td>
              </tr>
            </tbody>
          </table>
          <table v-for="(group, index) in estimate.external" :key="`ge_${index}`">
            <thead>
              <tr>
                <th style="width:80%">{{ group.name }}</th>
                <th class="text-right" style="width:20%">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                <td>{{ line.description }}</td>
                <td class="text-right">{{ (line.cost_each * line.quantity) | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table v-for="(group, index) in estimate.internal" :key="`g_${index}`">
            <tbody>
              <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                <td style="width:60%">{{ group.name }}</td>
                <td style="width: 20%">{{ line.hours }}:{{ line.minutes }}</td>
                <td style="width:20%" class="text-right">{{ (line.cost) | currency }}</td>
              </tr>
            </tbody>
          </table>
          <table v-for="(group, index) in estimate.external" :key="`ge_${index}`">
            <tbody>
              <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
                <td style="width:60%">{{ group.name }}</td>
                <td style="width:20%">{{ line.description }}</td>
                <td style="width:20%" class="text-right">
                  {{ (line.cost_each * line.quantity) | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="estimate.external.lines"
      class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <h4 class="pb-2">Expenses Estimate</h4>
        <table v-for="(group, index) in estimate.external" :key="`g_${index}`">
          <thead>
            <tr>
              <th style="width:60%">{{ group.name }}</th>
              <th style="width:20%">Hours</th>
              <th class="text-right" style="width:20%">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, index) in group.lines" :key="`l_${index}`">
              <td>{{ line.description }}</td>
              <td>{{ line.quantity }}</td>
              <td class="text-right">{{ (line.cost_each * line.quantity) | currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <table>
          <thead>
            <tr>
              <th>Total</th>
              <th class="text-right">{{ estimate.subTotal | currency }}</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div v-if="estimate.files.length > 0" class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <h4 class="pb-2">Files</h4>
        <table>
          <tbody>
            <tr v-for="(file, index) in estimate.files" :key="`f_${index}`">
              <td>
                <a
                  :href="`${baseUrl}/original/${file.assembly}__${file.name}.${file.ext}`"
                  target="_blank">{{ file.name }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-right">
        <button @click="$emit('select', estimate.id)" class="button small">Select</button>
        &nbsp;
        <button @click="$emit('cancel')" class="button small red">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EstimatePreview',
  props: [
    'estimate',
  ],
};
</script>

<template>
  <div class="grid-container">
    <div v-if="jobNumber.id === 0">
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-8">
          <ClientSelect :clientId="clientId" @clientSelected="setClient" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-8">
          <BrandSelect :selectedClient="client" @brandSelected="setBrand" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-8">
          <label>Short job description</label>
          <input type="text" v-model="description">
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-8">
          <button @click="saveJobNumber()" class="button">Save Job</button>
        </div>
      </div>
    </div>
    <div class="pt-4" v-else>
      <p>
        Job number: {{  jobNumber.job_number }}<br />
        Description: {{ jobNumber.description }}<br />
      </p>
      <p class="pt-4">
        <router-link :to="`/estimates/create/${jobNumber.id}`">
          <button class="button medium">Add Estimate</button>
        </router-link>
        &nbsp;
        <button @click="showEstimatesToCopy = true" class="button medium">
          Copy Estimate
        </button>
        &nbsp;
        <button @click="showRecordTime = true" class="button medium">Record Time</button>
      </p>
    </div>
    <EstimateSelect
      v-if="showEstimatesToCopy"
      :jobId="0"
      :jobNumber="jobNumber"
      @actionCancelled="showEstimatesToCopy = false"
      @estimateSelected="copyEstimate" />
    <RecordTime
      v-if="showRecordTime"
      :jobId="0"
      :jobNumberId="jobNumber.id"
      @timeSaved="showRecordTime = false" />
  </div>
</template>

<script>
import BrandSelect from '@/components/BrandSelect.vue';
import ClientSelect from '@/components/ClientSelect.vue';
import RecordTime from '@/views/time-records/components/RecordTime.vue';
import EstimateSelect from './components/EstimateSelect.vue';
import axios from '../../axios';

export default {
  name: 'CreateJobNumber',
  components: {
    BrandSelect,
    ClientSelect,
    EstimateSelect,
    RecordTime,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    clientId() {
      return this.$route.params.clientId;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      client: {
        id: 0,
        name: '',
        short_name: '',
      },
      brand: {
        id: 0,
        name: '',
        short_name: '',
      },
      description: '',
      jobNumber: {
        id: 0,
      },
      showEstimatesToCopy: false,
      showRecordTime: false,
    };
  },
  methods: {
    copyEstimate(value) {
      const postData = {};
      postData.estimateId = value;
      postData.jobNumberId = this.jobNumber.id;
      postData.userid = this.userid;
      axios.post(`/estimates/copyEstimateForJobNumber.json?token=${this.token}`, postData)
        .then((response) => {
          const searchValues = {};
          searchValues.brand = response.data.newEstimate.brand_id;
          searchValues.client = response.data.newEstimate.client_id;
          searchValues.jobNumber = '';
          this.$store.commit('setSearch', searchValues);
          this.$router.push('/job-numbers/index');
        })
        .catch(() => {
          this.failed = true;
        });
    },
    saveJobNumber() {
      const postData = {};
      postData.client = this.client;
      postData.brand = this.brand;
      postData.description = this.description;
      postData.userId = this.userid;
      axios.post(`/jobNumbers/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.jobNumber = response.data;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    setBrand(data) {
      this.brand = data;
    },
    setClient(data) {
      this.client = data;
    },
  },
};
</script>

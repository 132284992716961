<template>
  <div>
    <label>Select a client</label>
    <select v-model="client">
      <option
        v-for="(client, index) in clients"
        :key="`c_${index}`"
        :value="client">
        {{ client.name }}
      </option>
    </select>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'ClientSelect',
  props: [
    'clientId',
  ],
  data() {
    return {
      client: {},
      clients: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    client() {
      this.$emit('clientSelected', this.client);
    },
  },
  methods: {
    getClients() {
      axios.get(`/clients/fetchAll.json?token=${this.token}`)
        .then((response) => {
          this.clients = response.data.clients;
          if (this.clientId > 0) {
            const clientId = parseInt(this.clientId, 10);
            // eslint-disable-next-line
            this.client = this.clients.filter((cli) => cli.id === clientId)[0];
          }
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getClients();
    }, 100);
  },
};
</script>

<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-11">
        <h4 class="pb-5">Select estimate to copy</h4>
      </div>
      <div class="cell small-12 medium-1">
        <button
          @click="$emit('actionCancelled')"
          class="button red small">
          Cancel
        </button>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table>
          <tbody>
            <tr v-for="(estimate, index) in estimates" :key="`es_${index}`">
              <td style="min-width: 100px">{{ estimate.job_number.job_number }}</td>
              <td style="min-width: 500px">{{ estimate.estimate_includes }}</td>
              <td style="min-width: 200px">
                <button class="button small" @click="previewEstimate(estimate.id)">
                  Preview
                </button>&nbsp;
                <button
                  @click="$emit('estimateSelected', estimate.id)"
                  class="button small">
                  Copy estimate
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-right">
        <button
          @click="$emit('actionCancelled')"
          class="button red small">
          Cancel
        </button>
      </div>
    </div>
    <Preview
      @cancel="cancelPreview"
      @select="selectEstimate"
      :estimate="estimatePreview"
      v-if="estimatePreview.id > 0" />
  </div>
</template>

<script>
import axios from '../../../axios';
import Preview from './EstimatePreview.vue';

export default {
  name: 'JobNumbersEstimateSelect',
  props: [
    'jobNumber',
  ],
  components: {
    Preview,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      estimatePreview: {
        id: 0,
      },
      estimates: [],
    };
  },
  methods: {
    cancel() {
      this.$emit('actionCancelled');
    },
    cancelPreview() {
      this.estimatePreview = { id: 0 };
    },
    getEstimates() {
      const postData = {};
      postData.jobNumber = this.jobNumber;
      axios.post(`/estimates/getToDuplicate.json?token=${this.token}`, postData)
        .then((response) => {
          this.estimates = response.data.estimates;
        });
    },
    previewEstimate(estimateId) {
      axios.get(`/estimates/getOne/${estimateId}.json?token=${this.token}`)
        .then((response) => {
          this.estimatePreview = response.data.estimate;
        });
    },
    selectEstimate(value) {
      this.$emit('estimateSelected', value);
    },
  },
  mounted() {
    this.getEstimates();
  },
};
</script>

<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1>Create New Estimate for Job Number: {{ jobNumber.job_number }}</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link :to="`/estimates/index/`">
            <button class="button expanded">All Estimates</button>
          </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label>Job Number</label>
        <input type="text" disabled v-model="jobNumber.job_number" />
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label>Estimate includes</label>
        <textarea v-model="estimateIncludes"></textarea>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label>
          <input type="checkbox" v-model="showBreakdown"
          Show breakdown />
          Show breakdown of hours to client
        </label>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <label>Invoicing</label>
        <select v-model="invoicing">
          <option
            v-for="(parameter, index) in invoiceParameters"
            :key="`p_${index}`"
            :value="parameter">
            Deposit percentage: {{ parameter.deposit_percentage }}%
            Currency: {{ parameter.currency }}
          </option>
          <option value="enterOther">Other</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5" v-if="invoicing === 'enterOther'">
      <div class="cell small-6 medium-4">
        <label>Deposit %</label>
        <input type="text" v-model="invoicingOther.deposit">
      </div>
      <div class="cell small-6 medium-4">
        <label>Currency</label>
        <select v-model="invoicingOther.currency">
          <option value="£">£</option>
          <option value="$">$</option>
          <option value="€">€</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <button @click="saveEstimate" class="button">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'CreateEstimate',
  data() {
    return {
      invoiceParameters: [],
      invoicing: 0,
      invoicingOther: {
        deposit: 50,
        currency: '£',
      },
      jobNumber: {
        job_number: '',
      },
      estimateIncludes: '',
      showBreakdown: 0,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    jobNumberId() {
      return this.$route.params.jobNumberId;
    },
    depositValue() {
      let depositValue = 50;
      if (this.invoicing !== 'enterOther') {
        depositValue = this.invoicing.deposit_percentage;
      } else {
        depositValue = this.invoicingOther.deposit;
      }
      return depositValue;
    },
    currencyValue() {
      let currencyValue = '£';
      if (this.invoicing !== 'enterOther') {
        currencyValue = this.invoicing.currency;
      } else {
        currencyValue = this.invoicingOther.currency;
      }
      return currencyValue;
    },
  },
  methods: {
    getInvoiceParameters() {
      const clientId = this.jobNumber.client_id;
      axios.get(`/invoiceParameters/getByClientId/${clientId}.json?token=${this.token}`)
        .then((response) => {
          this.invoiceParameters = response.data.invoiceParameters;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getJobNumber() {
      axios.get(`/jobNumbers/getOne/${this.jobNumberId}.json?token=${this.token}`)
        .then((response) => {
          this.jobNumber = response.data.jobNumber;
          this.getInvoiceParameters();
        })
        .catch(() => {
          this.failed = true;
        });
    },
    saveEstimate() {
      const postData = {};
      postData.client_id = this.jobNumber.client_id;
      postData.brand_id = this.jobNumber.brand_id;
      postData.job_number_id = this.jobNumber.id;
      postData.user_id = this.$store.state.userid;
      postData.estimate_includes = this.estimateIncludes;
      postData.show_breakdown = this.showBreakdown;
      postData.deposit = this.depositValue;
      postData.currency = this.currencyValue;
      axios.post(`/estimates/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.$router.push(`/estimates/add-lines/${response.data.estimate.id}`);
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    this.getJobNumber();
  },
};
</script>

<template>
  <div>
    <label>Select a brand</label>
    <select v-model="brand">
      <option
        v-for="(brand, index) in brands"
        :key="`c_${index}`"
        :value="brand">
        {{ brand.name }}
      </option>
    </select>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'BrandSelect',
  props: [
    'selectedClient',
  ],
  data() {
    return {
      brand: {},
      brands: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    selectedClient: {
      handler() {
        this.getBrands();
      },
      deep: true,
    },
    brand() {
      this.$emit('brandSelected', this.brand);
    },
  },
  methods: {
    getBrands() {
      axios.get(`/brands/fetchAll/${this.selectedClient.id}.json?token=${this.token}`)
        .then((response) => {
          this.brands = response.data;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
};
</script>
